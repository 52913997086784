import React from "react";

const Presented = ({ sponsor_message, underwriters = [] }) => {
  // Find the primary sponsor
  const underwriterPrimary = underwriters.find((sponsor) => sponsor.is_primary === true);

  if (!underwriterPrimary) {
    return null;
  }

  return (
    <section className="c-presented-by">
      <h4 className="c-presented-by__sponsor-message">{sponsor_message}</h4>
      {underwriterPrimary.url ? (
        <a href={underwriterPrimary.url} target="_blank" data-action="click logo - presented by">
          <img
            className="c-presented-by__sponsor-logo"
            src={underwriterPrimary.image}
            alt={`${underwriterPrimary.name} Logo`}
          />
        </a>
      ) : (
        <img
          className="c-presented-by__sponsor-logo"
          src={underwriterPrimary.image}
          alt={`${underwriterPrimary.name} Logo`}
        />
      )}
    </section>
  );
};

export default Presented;
