import React from "react";
import Card from "../card";
import SectionLabel from "../section-label";

const FeaturedLinks = ({ featured_links = [], featured_links_display_title }) => {
  if (!featured_links.length) {
    return null;
  }
  return (
    <section className="c-featured alt-bg-color">
      <SectionLabel
        name={featured_links_display_title || "Featured"}
        className="events"
        theme="dark"
      />
      <ul
        className={`c-featured-container total-${featured_links.length}`}
        data-action="click link - featured"
      >
        {featured_links.map((link, index) => (
          <Card
            className="c-featured-card"
            key={index}
            useInview={true}
            alt={link.alt_text}
            lead_image_card_thumbnail={link.image}
            action="featured"
            url={link.url}
            sponsored={link.sponsored}
          >
            {link.label && !link.sponsored ? (
              <p className="c-featured-card__label">{link.label}</p>
            ) : (
              <p className="c-featured-card__label is-sponsored">Underwriter Content</p>
            )}
            <h3
              className="c-featured-card__title"
              dangerouslySetInnerHTML={{ __html: link.hed }}
            ></h3>
            {link.author && (
              <p
                className="c-featured-card__author"
                dangerouslySetInnerHTML={{ __html: `${link.author}` }}
              />
            )}
          </Card>
        ))}
      </ul>
    </section>
  );
};

export default FeaturedLinks;
