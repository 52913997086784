import React, { useState, useRef, useEffect } from "react";
import SectionLabel from "../section-label";

const Content = ({ title, content }) => {

  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  const [height, setHeight] = useState(5000); // set a very large initial height
  const contentRef = useRef(null);
  const isFirstRender = useRef(true);

  // Idea for checking the height on first render: https://levelup.gitconnected.com/reactjs-approach-dynamic-animation-with-height-auto-or-unknown-height-a55562e01f59

  useEffect(() => {
    // Get the element height when first expanded
    if (contentRef.current && !isFirstRender.current) {
      // check for the edge case that the accordion was expanded too quickly
      if (
        height > contentRef.current.offsetHeight &&
        height !== 5000 // if we get the initial height here something went wrong
      ) {
        return;
      }
      // Set the height once
      setHeight(contentRef.current.offsetHeight);
    }
    if (isOpen && isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [isOpen]);

  // Pass the accordion height to our CSS
  const cssVars = {
    "--accordionHeight": `${height}px`,
  };

  return(
    <div className="c-accordion-content-container">
      <h3 aria-expanded={isOpen} className={`c-accordion-content__title ${isOpen ? "is-open" : ""}`} onClick={handleClick} dangerouslySetInnerHTML={{ __html: title }} />
      <div ref={contentRef} className={`c-accordion-content ${isOpen ? "is-open" : ""}`} style={cssVars} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

const Accordions = ({ accordions = [] }) => {
  if (!accordions.length) {
    return null;
  }

  return (
    <>
      {accordions.map((section, i) => (
        <section
          key={i}
          id={section.title}
          className={"c-accordion is-inview"}
        >
          <SectionLabel name={section.title} className="events" theme="dark" />
          <div className={`c-accordion-container`}>
            {section.content.map((content, index) => (
              <Content key={index} {...content} />
            ))}
          </div>
        </section>
      ))}
    </>
  );
};

export default Accordions;
