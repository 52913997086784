import React from "react";

export const NavContext = React.createContext({});

const NavLinkBasic = ({
  className = "c-nav__item",
  url,
  onClick = null,
  label,
  dataLabel = url,
}) => {
  return (
    <li className={className} role="menuitem">
      <a
        href={url}
        onClick={onClick}
        className="c-nav__item-link"
        data-action="click - nav"
        data-label={dataLabel.toLowerCase()}
      >
        {label}
      </a>
    </li>
  );
};

const NavLink = ({ section, label, url }) => {
  const { activeMenu, setActiveMenu } = React.useContext(NavContext);
  const handleClick = (e) => {
    setActiveMenu(section);
    e.preventDefault();
    const sectionEl = document.getElementById(`${section}`);
    sectionEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <NavLinkBasic
      className={
        activeMenu === section ? `c-nav__item ${section} is-selected` : `c-nav__item ${section}`
      }
      url={url || section}
      onClick={handleClick}
      label={label}
      dataLabel={section}
    />
  );
};

export default NavLink;
export { NavLinkBasic };
