/**
 * @param {boolean} is_hybrid_event
 * @param {boolean} is_virtual
 * @param {object} city
 * @returns a string for the place name. Handles virtual and hybrid events
 */
const getPlaceName = (is_hybrid_event, is_virtual, city) => {
  let placeName = "";
  let hasCity = city && city.name;
  // HYBRID EVENTS
  if (is_hybrid_event) {
    placeName = hasCity ? `${city.name} or Virtual` : "In-Person & Virtual Event";
  }
  // VIRTUAL EVENTS
  else if (is_virtual) {
    placeName = "Virtual Event";
  }
  // IN-PERSON EVENTS
  else {
    placeName = hasCity ? city.name : "";
  }
  return placeName;
};

export default getPlaceName;
