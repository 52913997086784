import React, { useRef } from "react";
import useVisibility from "../../util/use-visibility";
import { assetPath } from "../../constants";
import { formatTime, formatTimezone, formatWeekdayYear } from "../../util/format-datetime";
import getPlaceName from "../../util/get-place-name";

const Details = ({
  address,
  start_datetime,
  end_datetime,
  hide_start_time,
  hide_start_day,
  displayed_timezone,
  custom_datetime,
  share_tweet,
  is_virtual,
  is_hybrid_event,
  slug,
  city = {},
  city_background_image,
}) => {
  // get the correct formats for date and time
  let time = formatTime(start_datetime, displayed_timezone);
  let date = formatWeekdayYear(start_datetime, displayed_timezone, hide_start_day, end_datetime);
  let timezone = formatTimezone(displayed_timezone);

  // LinkedIn share requires live URLs, so it will error before the event's URL is published.
  // You can replace eventUrl with a published URL to confirm that LinkedIn share works.
  let eventUrl = new URL(`https://theatlantic.com/live/${slug}/`);
  let facebookUrl = eventUrl;
  let twitterUrl = eventUrl;
  facebookUrl.search = `?utm_source=facebook&utm_medium=social&utm_campaign=share`;
  twitterUrl.search = `?utm_source=twitter&utm_medium=social&utm_campaign=share`;
  facebookUrl = encodeURIComponent(facebookUrl);
  twitterUrl = encodeURIComponent(twitterUrl);

  let background = city_background_image || "";
  if (!background && city) {
    background = city.image;
  }

  let ref = useRef(null);
  const visible = useVisibility(ref);

  const placeName = getPlaceName(is_hybrid_event, is_virtual, city);

  return (
    <section
      ref={ref}
      className={`c-details ${visible ? "is-inview" : ""}`}
      style={{ backgroundImage: `url('${background}')` }}
    >
      <h4 className="c-details-label">Event Details</h4>
      {placeName && <h2 className="c-details-place">{placeName}</h2>}
      {address ? <p className="c-details-address">{address}</p> : null}
      <p className="c-details-date">{custom_datetime || date}</p>
      {!hide_start_time ? (
        <p className="c-details-time">
          {time} {timezone}
        </p>
      ) : null}
      <ul className="c-details-social" aria-label="Share" data-action="click link - share">
        <li className="c-details-social__share-label" role="presentation">
          Share
        </li>
        <li className="c-details-social__share-item">
          <a
            aria-label="Share on Facebook"
            role="button"
            href={`https://www.facebook.com/sharer/sharer.php?u=${facebookUrl}`}
            data-width="430"
            data-height="620"
            target="_blank"
            rel="noopener noreferrer"
            data-label="facebook"
          >
            <span className="sr-only">Facebook</span>
            <img src={`${assetPath}/social-facebook.svg`} alt="Facebook Logo" />
          </a>
        </li>
        <li className="c-details-social__share-item">
          <a
            aria-label="Share on Twitter"
            role="button"
            href={
              `https://twitter.com/intent/tweet` +
              `?text=${share_tweet ? encodeURIComponent(share_tweet) : ""}` +
              `&url=${twitterUrl}`
            }
            data-width="600"
            data-height="250"
            target="_blank"
            rel="noopener noreferrer"
            data-label="twitter"
          >
            <span className="sr-only">Twitter</span>
            <img src={`${assetPath}/social-twitter.svg`} alt="Twitter Logo" />
          </a>
        </li>
        <li className="c-details-social__share-item">
          <a
            aria-label="Share on LinkedIn"
            role="button"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
              eventUrl
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            data-label="linkedin"
          >
            <span className="sr-only">LinkedIn</span>
            <img src={`${assetPath}/social-linkedin.svg`} alt="LinkedIn Logo" />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Details;
