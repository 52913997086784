function formatter({ datetime, displayedTimezone, options, isTime }) {
  const formatOptions = { ...options };

  if (displayedTimezone === "") {
    displayedTimezone = undefined;
  }

  const rawDate = datetime ? new Date(datetime) : new Date();

  // eslint-disable-next-line eqeqeq
  if (rawDate == "Invalid Date") {
    console.error("Invalid Date " + datetime);
    return "";
  }

  if (displayedTimezone) {
    formatOptions.timeZone = displayedTimezone;
  }

  if (isTime) {
    return rawDate.toLocaleTimeString("en-US", formatOptions);
  } else {
    return rawDate.toLocaleDateString("en-US", formatOptions);
  }
}

/**
 * Monday, June 1
 */
export function formatWeekday(datetime, displayedTimezone, hide_start_day) {
  let options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  if (hide_start_day) {
    options = {
      month: "long",
      year: "numeric",
    };
  }

  return formatter({ datetime, displayedTimezone, options });
}

/**
 * 1
 */
export function formatDayOfMonth(datetime, displayedTimezone) {
  let options = {
    day: "numeric",
  };
  return formatter({ datetime, displayedTimezone, options });
}

/**
 * Monday, June 1, 2021
 */
export function formatWeekdayYear(datetime, displayedTimezone, hide_start_day, end_datetime) {
  if (displayedTimezone === "") {
    displayedTimezone = undefined;
  }

  const rawDate = new Date(datetime);

  // eslint-disable-next-line eqeqeq
  if (rawDate == "Invalid Date") {
    console.error("Invalid Date " + datetime);
    return "";
  }

  let options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  if (hide_start_day) {
    options = {
      month: "long",
    };
  }

  if (displayedTimezone) {
    options.timeZone = displayedTimezone;
  }

  // Always add year at the end of the date
  let dateStr = rawDate.toLocaleDateString("en-US", options);
  let yearStr = rawDate.getFullYear();

  // If we are hiding the day, no comma
  if (hide_start_day) {
    dateStr = `${dateStr} ${yearStr}`;
  }
  // If we are showing the day (and no end date) add a comma
  if (!hide_start_day && !end_datetime) {
    dateStr = `${dateStr}, ${yearStr}`;
  }

  // When we are showing the start day and have an end_datetime
  // add a day range: "Monday, June 1 - 3, 2021"
  if (!hide_start_day && end_datetime) {
    const endDate = new Date(end_datetime);
    let endStr;
    yearStr = endDate.getFullYear();
    // compare the months, if they are different also include the new month:
    // "Wednesday, June 30 - July 2, 2021"
    if (rawDate.getMonth() + 1 === endDate.getMonth() + 1) {
      endStr = endDate.toLocaleDateString("en-US", { day: "numeric" });
    } else {
      endStr = endDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    }
    dateStr = `${dateStr} - ${endStr}, ${yearStr}`;
  }

  return dateStr;
}

/**
 * June 1, 2020
 */
export function formatFullyear(datetime, displayedTimezone, hide_start_day) {
  const options = { month: "long", day: "numeric", year: "numeric" };
  if (hide_start_day) {
    options.day = undefined;
  }

  return formatter({ datetime, displayedTimezone, options });
}

/**
 * EDT
 */
export function formatTimezone(displayedTimezone) {
  const formatted = formatter({
    displayedTimezone,
    options: { timeZoneName: "short" },
    isTime: true,
  });
  return formatted.split(" ")[2];
}

/**
 * 01:15
 */
export function formatTime(datetime, displayedTimezone) {
  const time = formatter({
    datetime,
    displayedTimezone,
    options: {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      second: undefined,
    },
    isTime: true,
  });
  return time.replace("AM", "am").replace("PM", "pm");
}

/**
 * Sunday
 */
export function formatWeekdayShort(datetime, displayedTimezone) {
  return formatter({
    datetime,
    displayedTimezone,
    options: {
      weekday: "short",
      month: "short",
      day: "numeric",
    },
  });
}

/**
 * May 1
 */
export function formatDateShort(datetime, displayedTimezone) {
  return formatter({
    datetime,
    displayedTimezone,
    options: {
      month: "short",
      day: "numeric",
    },
  });
}

/**
 * Sep.
 */
export function formatMonth(datetime, displayedTimezone) {
  return formatter({
    datetime,
    displayedTimezone,
    options: {
      month: "short",
    },
  });
}
