import React, { useState, useEffect, useRef } from 'react';

import SectionLabel from "../section-label";

const LogoAd = () => {
  const [adLoaded, setAdLoaded] = useState(false);
  const adRef = useRef(null);

  useEffect(() => {
    const adElement = adRef.current;

    const handleAdLoaded = () => {
      setAdLoaded(true);
    };

    if (adElement) {
      adElement.addEventListener('ad-loaded', handleAdLoaded);
    }

    return () => {
      if (adElement) {
        adElement.removeEventListener('ad-loaded', handleAdLoaded);
      }
    };
  }, []);


  return (
    <>
      {adLoaded && <SectionLabel name="Supporting Sponsor" className="events c-logo-ad-section-label" theme="dark" />}
      <gpt-ad ref={adRef} format="logo" targeting-pos="event-logo">
        <gpt-sizeset viewport-size="[0, 0]" sizes="logo"></gpt-sizeset>
      </gpt-ad>
    </>
  );
};

export default LogoAd;
