import React, { useRef } from "react";
import useVisibility from "../../util/use-visibility";
import SectionLabel from "../section-label";

const Contacts = ({ label, title, email }) => (
  <div className="c-questions-contact">
    <h4 className="c-questions-contact__label">{label}</h4>
    <h3 className="c-questions-contact__title" dangerouslySetInnerHTML={{ __html: title }} />
    <a
      className="c-questions-contact__address"
      href={`mailto:${email}`}
      data-action="click link - contact us"
    >
      {email}
    </a>
  </div>
);

const Questions = ({ contacts = [] }) => {
  if (!contacts.length) {
    return null;
  }
  let ref = useRef(null);
  useVisibility(ref, "contact");
  const visible = useVisibility(ref);

  return (
    <section
      ref={ref}
      id="contact"
      className={`c-questions alt-bg-color ${visible ? "is-inview" : ""}`}
    >
      <SectionLabel name="Questions?" className="events" theme="dark" />
      <div className={`c-questions-contacts-container total-${contacts.length}`}>
        {contacts.map((contact, index) => (
          <Contacts key={index} {...contact} />
        ))}
      </div>
    </section>
  );
};

export default Questions;
