import React from "react";

const Ad = () => {
  return (
    <gpt-ad class="c-ad" targeting-pos="boxpremium">
      <gpt-sizeset viewport-size="[0, 0]" sizes="mobile-wide"></gpt-sizeset>
      <gpt-sizeset viewport-size="[976, 0]" sizes="desktop-wide"></gpt-sizeset>
    </gpt-ad>
  );
};

export default Ad;
