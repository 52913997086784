import React, { forwardRef } from "react";

// Images need a specified height to show a grey box before they load
const Image = forwardRef(({ shouldLoad, className, src, alt, style = {} }, ref) => {
  Image.displayName = "image";
  return (
    <figure ref={ref} style={style} className={`${className} c-figure`}>
      {src && (
        <img
          className={`c-img ${shouldLoad ? "c-img--loaded" : "c-img--lazy"}`}
          src={shouldLoad ? src : null}
          alt={alt ? alt : ""}
        />
      )}
    </figure>
  );
});

export default Image;
