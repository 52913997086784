import { useEffect, useContext, useState } from "react";
import { NavContext } from "../components/nav-link";
import isServer from "./is-server";

const options = {
  rootMargin: "0px 0px -200px 0px",
  threshold: 0,
};

function useVisibility(ref, name) {
  if (isServer) {
    return;
  }

  const { setActiveMenu } = useContext(NavContext);
  const [visible, setVisible] = useState(false);

  function intersectionIOCallback(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && name) {
        // when this section is in-view add selected class to the nav
        setActiveMenu(name);
        // if no name is specificed we are just looking to see if this element is visible
      } else if (entry.isIntersecting && !name) {
        setVisible(true);
      }
    });
  }

  useEffect(
    () => {
      const observer = new IntersectionObserver(intersectionIOCallback, options);
      if (ref.current) {
        observer.observe(ref.current);
      }

      // clean up the observer when the component unmounts
      return () => observer.disconnect();
    },
    // if the current ref changes, reconnect
    [ref.current]
  );
  return visible;
}

export default useVisibility;
