import React, { useRef, useState, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const Legal = () => (
  <p className="c-newsletter__legal">
    Subject to{" "}
    <a className="c-newsletter__legal-link" href="https://www.theatlantic.com/privacy-policy/">
      The Atlantic&apos;s Privacy Policy
    </a>{" "}
    and{" "}
    <a
      className="c-newsletter__legal-link"
      href="https://www.theatlantic.com/terms-and-conditions/"
    >
      Terms and Conditions
    </a>
    .
  </p>
);

const SignUp = ({ signup_text }) => {
  const newsletterID = 55556;
  let refButton = useRef(null);
  let refForm = useRef(null);
  const [status, setStatus] = useState("INITIAL");
  const [statusMessage, setStatusMessage] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("submit");

      // disable button after submit
      refButton.current.setAttribute("disabled", true);

      const formData = new FormData(refForm.current);
      formData.set("recaptcha_token", token);

      const result = await submitSignup(formData);
      const networkError = result?.error;

      if (networkError) {
        setStatus("ERROR");
        setStatusMessage(networkError);
        return {
          FORM_ERROR: "An unknown error occurred.",
        };
      } else {
        setStatus("SUCCESS");
        setStatusMessage("Thanks for signing up!");

        // get form field value
        const email = formData.get("email");

        window.top.dataLayer = window.top.dataLayer || [];
        window.top.dataLayer.push({
          event: "Newsletter Signup",
          user: {
            email,
            newsletter: {
              id: newsletterID,
              name: "Atlantic Live Newsletter",
            },
          },
        });
      }
    },
    [executeRecaptcha]
  );

  const submitSignup = async (formData) => {
    const urlEncodedData = new URLSearchParams();
    for (const pair of formData.entries()) {
      urlEncodedData.append(pair[0], pair[1]);
    }

    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "include",
      body: urlEncodedData.toString(),
    };
    const newsletterSignUp = `https://accounts-api.theatlantic.com/api/v1/newsletters/sign-up/`;

    return fetch(newsletterSignUp, options)
      .then((res) => res.json())
      .then((data) => {
        /**
         * Handle 400 errors
         * @see https://developer.mozilla.org/en-US/docs/Web/API/fetch
         */
        if (!data.success) {
          return handleError(data);
        }
      })
      .catch((err) => {
        return handleError(err);
      });
  };

  const handleError = (err) => {
    console.error(err); // eslint-disable-line no-console
    // enable button after error
    refButton.current.removeAttribute("disabled");
    /**
     * Grab error from response
     * @see https://github.com/theatlantic/accounts-api/blob/f963289b94ebc772c932c234f03c572b2a55d35c/src/triton/app/newsletters/views.py#L114-L135
     */
    let errorMessage =
      typeof err?.error === "object"
        ? err?.error?.[0]?.email?.[0]
        : err?.error || "Enter a valid email address.";

    return {
      success: false,
      error: errorMessage,
      user: null,
    };
  };

  let statusCode = status.toLowerCase();

  return (
    <section className="c-signup">
      <h4 className="c-signup-text">{signup_text}</h4>
      <div className="c-signup__col2">
        <form
          onSubmit={onSubmit}
          className="c-signup-form"
          ref={refForm}
          data-action="click button - newsletter signup"
        >
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            className={`c-signup-form__field ${statusCode ? statusCode : ""}`}
            required={true}
          />
          <span className={`c-signup-form__message ${statusCode ? statusCode : ""}`}>
            {statusMessage}
          </span>
          <input type="hidden" name="newsletters" value={newsletterID}></input>
          <input type="hidden" name="recaptcha_token" value=""></input>
          <input
            ref={refButton}
            type="submit"
            value="Submit"
            className="c-signup-form__button"
            data-label="submit"
            disabled={false}
          />
        </form>
        <Legal />
      </div>
    </section>
  );
};

export default SignUp;
