import React, { useState, useCallback, useRef } from "react";

import SessionContent from "./session-content";

import { FormatLabels } from "./format-labels";
import { formatTimezone, formatTime } from "../../util/format-datetime";
import useCompareTime from "../../util/use-compare-time";

const Session = ({
  session: {
    title,
    description,
    start_datetime,
    hide_start_time,
    end_datetime,
    underwriters,
    speakers: speakersSession = [],
    format,
    action_url,
    action_text,
    location,
    tracks = [],
  },
  displayedTimezone,
  speakersAll,
  underwritersAll,
  status,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  // Compare the current time to the time of each session
  const [hasHappened, isHappening, hasNotHappened] = useCompareTime(new Date(start_datetime), new Date(end_datetime));

  // Match the underwriter id to the correct underwriter for that session
  const underwritersSession = underwriters.map((underwriter) => underwriter.id);
  const underwritersMatched = underwritersSession.map((underwriter_id) =>
    underwritersAll.find((underwriter) => underwriter.id === underwriter_id)
  );
  // Match the speaker id to the correct speaker for that session
  const speakersMatched = speakersSession.map((speaker_id) =>
    speakersAll.find((speaker) => speaker.id === speaker_id)
  );

  const sessionContent =
    underwritersMatched.length ||
    description ||
    action_url ||
    speakersMatched.length ||
    location ||
    format ||
    tracks.length;

  return (
    <div className={`c-session ${hasHappened ? "has-happened" : ""} ${status === "ARCHIVED" ? "is-archived" : ""}`}>
      {isHappening && (<div className="c-session-happening"><span className="is-happening"></span>Happening Now</div>)}
      <Time
        hide_start_time={hide_start_time}
        start_datetime={start_datetime}
        end_datetime={end_datetime}
        displayedTimezone={displayedTimezone}
      />
      <Title title={title} underwritersMatched={underwritersMatched} />
      {location ? (
        <span className="c-format-label">{location}</span>
      ) : (
        <FormatLabels format={format} />
      )}
      {sessionContent ? (
        <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed}></CollapseButton>
      ) : (
        <div className="c-session_collapse"></div>
      )}
      <SessionContent
        speakersMatched={speakersMatched}
        underwritersMatched={underwritersMatched}
        description={description}
        action_url={action_url}
        action_text={action_text}
        collapsed={collapsed}
        start_datetime={start_datetime}
        end_datetime={end_datetime}
        hide_start_time={hide_start_time}
        location={location}
        tracks={tracks}
        format={format}
      />
    </div>
  );
};

const Title = ({ title, underwritersMatched }) => {
  let underwritersText = "";
  if (underwritersMatched.length === 1) {
    underwritersText = `Underwritten by ${underwritersMatched[0].name}`;
  } else if (underwritersMatched.length > 1) {
    const lastUnderwriter = underwritersMatched[underwritersMatched.length - 1].name;
    const otherUnderwriters = underwritersMatched
      .slice(0, -1)
      .map((underwriter) => underwriter.name)
      .join(", ");
    underwritersText = `Underwritten by ${otherUnderwriters} and ${lastUnderwriter}`;
  }

  return (
    <div className="c-session-title">
      <h3 className="c-session-title__text" dangerouslySetInnerHTML={{ __html: title }}></h3>
      {!!underwritersMatched.length && (
        <span className="c-session-title__underwriter">{underwritersText}</span>
      )}
    </div>
  );
};

const Time = ({ hide_start_time, displayedTimezone, start_datetime }) => {
  // Format date/time from start_datetime
  let time = "";
  if (!hide_start_time) {
    const timezone = formatTimezone(displayedTimezone);
    time = `${formatTime(start_datetime, displayedTimezone)} ${timezone}`;
  }
  return <div className="c-session__time">{`${time}`}</div>;
};

const CollapseButton = ({ collapsed, setCollapsed }) => {
  let refBtn = useRef(null);

  const handleClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <div className={`c-session_collapse`} aria-hidden={true}>
      <button
        ref={refBtn}
        className={`c-session_collapse__button ${collapsed ? "c-session_collapse--collapsed" : ""}`}
        onClick={handleClick}
        tabIndex={-1}
      >
        {collapsed ? "Learn More +" : "Collapse -"}
      </button>
    </div>
  );
};

export default Session;
