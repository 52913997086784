import React, { useRef } from "react";
import useVisibility from "../../util/use-visibility";
import SectionLabel from "../section-label";
import Button from "../button";

const Tier = ({ title, text }) => (
  <div className="c-passes-tier">
    <h3 className="c-passes-title" dangerouslySetInnerHTML={{ __html: title }} />
    <p className="c-passes-text" dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

const Passes = ({
  passes = [],
  accent_color = "#2C2825",
  isSubscriber,
  register_link_cta,
  sub_register_link,
  register_link,
}) => {
  if (!passes.length) {
    return null;
  }
  let ref = useRef(null);
  const visible = useVisibility(ref);

  // Check for subscriber status and give the correct register link
  const link = isSubscriber && sub_register_link ? sub_register_link : register_link;

  return (
    <section
      ref={ref}
      className={`c-passes ${visible ? "is-inview" : ""}`}
      style={{ backgroundColor: accent_color }}
      id="passes"
    >
      <SectionLabel name="Passes" className="events" theme="light" />
      <div className="c-passes-container">
        {passes.map((pass, index) => (
          <Tier key={index} {...pass} />
        ))}
        {link && (
          <Button
            className="c-passes-button"
            label={register_link_cta ? register_link_cta : "Register"}
            link={link}
          />
        )}
      </div>
    </section>
  );
};

export default Passes;
