import React, { useRef, useEffect, useState } from "react";
import useEntitlements from "../../util/use-entitlements";
import { formatWeekdayYear } from "../../util/format-datetime";
import getPlaceName from "../../util/get-place-name";
import getDeviceType from "../../util/get-device-type";

const HighTouchLeadArt = ({
  title,
  lead_image,
  lead_image_mobile,
  lead_image_dimensions,
  lead_image_mobile_dimensions,
  lead_video,
}) => {
  // Get the lead image aspect ratio
  const leadAspectRatio = `${(lead_image_dimensions.height / lead_image_dimensions.width) * 100}`;
  const leadMobileAspectRatio = `${
    (lead_image_mobile_dimensions.height / lead_image_mobile_dimensions.width) * 100
  }`;
  // Pass the image dimensions to the CSS - limit max ratio to 16:9 (desktop) and 4:3 (mobile)
  const cssVars = {
    "--leadImageRatio": `${leadAspectRatio < 56.25 ? leadAspectRatio : `56.25`}%`,
    "--leadImageMobileRatio": `${leadMobileAspectRatio < 75 ? leadMobileAspectRatio : `75`}%`,
  };
  return (
    <div className="c-ht-header__image" style={cssVars}>
      {lead_image && lead_video && (
        <BackgroundLeadVideo lead_video={lead_video} lead_image={lead_image} />
      )}
      <picture className={lead_video && "has-video"}>
        <source srcSet={lead_image} media="(min-width: 768px)" />
        <img src={lead_image_mobile} alt={title} />
      </picture>
    </div>
  );
};

const BackgroundLeadVideo = ({ lead_video, lead_image }) => {
  const videoRef = useRef();
  const [deviceString, setDeviceString] = useState(false);

  useEffect(() => {
    setDeviceString(getDeviceType());
  }, []);

  useEffect(() => {
    if (deviceString) {
      videoRef.current.load();
    }
  }, [deviceString]);

  return (
    <div className="c-header__video">
      <video ref={videoRef} autoPlay={true} muted loop playsInline poster={lead_image}>
        <source src={deviceString === "Desktop" ? lead_video : undefined} type="video/mp4" />
      </video>
    </div>
  );
};

const BackgroundLeadImage = ({ lead_image, lead_image_mobile }) => {
  // Pass the image paths to CSS
  const cssVars = {
    "--leadImage": `url(${lead_image})`,
    "--leadImageMobile": `url(${lead_image_mobile})`,
  };

  return <div className={`c-header__image ${lead_image ? "" : "no-image"}`} style={cssVars}></div>;
};

const StandardHeader = ({
  title,
  dateStr,
  custom_datetime,
  is_virtual,
  is_hybrid_event,
  city = {},
  link,
  status,
  imageProps,
  register_link_cta,
}) => {
  return (
    <section className="c-header">
      {imageProps.lead_image && imageProps.lead_video && <BackgroundLeadVideo {...imageProps} />}
      <BackgroundLeadImage {...imageProps} />
      <div className="c-header-container">
        <h3 className="c-event-date-place">
          {custom_datetime || dateStr} | {getPlaceName(is_hybrid_event, is_virtual, city)}
        </h3>
        <h1 className="c-header__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
        {status !== "ARCHIVED" && (
          <div className="c-register">
            <Button link={link} status={status} register_link_cta={register_link_cta} />
          </div>
        )}
      </div>
    </section>
  );
};

const HighTouchHeader = ({ title, imageProps }) => {
  return (
    <section className="c-ht-header">
      <HighTouchLeadArt {...imageProps} />
      <div className="c-ht-header-container">
        <h1 className="c-ht-header__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
    </section>
  );
};

const Button = ({ link, status, register_link_cta }) => {
  let text = "Register";

  if (register_link_cta) {
    // Custom button text overrides all default status text
    text = register_link_cta;
  } else if (status === "DURING") {
    text = "Watch Now";
  }

  return (
    <a
      className="c-event-register"
      href={link}
      aria-label={text}
      data-action="click link - intro"
      data-label={text.toLowerCase()}
    >
      {text}
    </a>
  );
};

const Header = ({
  title,
  is_high_touch = false,
  lead_image,
  lead_image_mobile,
  lead_image_dimensions,
  lead_image_mobile_dimensions,
  lead_video,
  start_datetime,
  end_datetime,
  displayed_timezone,
  custom_datetime,
  hide_start_day,
  city,
  is_virtual,
  is_hybrid_event,
  register_link,
  sub_register_link,
  status,
  register_link_cta,
}) => {
  const dateStr = formatWeekdayYear(
    start_datetime,
    displayed_timezone,
    hide_start_day,
    end_datetime
  );

  const imageProps = {
    title,
    lead_image,
    lead_image_mobile,
    lead_image_dimensions,
    lead_image_mobile_dimensions,
    lead_video,
  };

  const headerProps = {
    title,
    dateStr,
    custom_datetime,
    is_virtual,
    is_hybrid_event,
    city,
    status,
    register_link_cta,
  };

  const { isSubscriber } = useEntitlements();

  // Check for subscriber status and give the correct register link
  let link = register_link;
  if (isSubscriber && sub_register_link) {
    link = sub_register_link;
  }

  if (is_high_touch) {
    return <HighTouchHeader imageProps={imageProps} link={link} {...headerProps} />;
  } else {
    return <StandardHeader imageProps={imageProps} link={link} {...headerProps} />;
  }
};

export default Header;
