import React, { useRef } from "react";
import Button from "../button";

const ExperiencePromo = ({
  // Formerly the in-person promo component, now the event promo component.
  // Highlights a single image and copy for the event.
  register_link,
  event_promo = "",
  event_promo_images,
  event_promo_link_cta,
  event_promo_link,
}) => {
  if (!event_promo || !event_promo_images) {
    return null;
  }
  return (
    <section id="experience" className="c-experience alt-bg-color">
      <div className="c-experience-container">
        <ExperiencePromoImage promoImage={event_promo_images[0]} />
        <div className="c-experience-copy">
          <div dangerouslySetInnerHTML={{ __html: event_promo }}></div>
          <Button
            label={event_promo_link_cta ? event_promo_link_cta : "Learn More"}
            link={event_promo_link ? event_promo_link : register_link}
          />
        </div>
      </div>
    </section>
  );
};

const ExperiencePromoImage = ({ promoImage }) => {
  let visibleRef = useRef("experience");

  return (
    <div className="c-experiencepromoimage">
      <div className="c-experiencepromoimage-container" ref={visibleRef}>
        <img
          className="c-experiencepromoimage_img"
          key={promoImage.id}
          src={promoImage.image}
          alt=""
        />
      </div>
    </div>
  );
};

export default ExperiencePromo;
