import React, { useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Nav from "./nav";
import { NavContext } from "../nav-link";
import Header from "./header";
import Intro from "./intro";
import Presented from "./presented-by";
import Passes from "./passes";
import FeaturedLinks from "./featured-links";
import Ad from "./ad";
import AccessibilityDisclaimer from "./disclaimer";
import Subscribe from "./subscribe";
import Speakers from "./speakers";
import ExperiencePromo from "./experience";
import Agenda from "./agenda";
import Sponsors from "./sponsors";
import Details from "./details";
import Accordions from "./accordion";
import Questions from "./questions";
import Related from "./related";
import Footer from "../footer";
import AdsConfig from "./ads-config";
import ErrorBoundary from "../error-boundary";
import Slideshow from "./slideshow";
import SignUp from "../sign-up";
import LogoAd from "./logo-ad";

const EventBody = ({ data }) => {
  const [activeMenu, setActiveMenu] = useState("about");

  return (
    <NavContext.Provider value={{ activeMenu, setActiveMenu }}>
      <ErrorBoundary>
        <Nav {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Header {...data} />
      </ErrorBoundary>
      {data.status === "DURING" &&
      <ErrorBoundary>
        <Agenda {...data} />
      </ErrorBoundary>
      }
      <ErrorBoundary>
        <Intro {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <GoogleReCaptchaProvider reCaptchaKey="6Lc9Z7AUAAAAAEYS1dgAG2_6tT3KLqZQ1z4kbDRc">
          <SignUp key="1234" signup_text="Sign Up to Receive Updates" {...data} />
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
      <ErrorBoundary>
        <Ad />
      </ErrorBoundary>
      <ErrorBoundary>
        <Passes {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <AccessibilityDisclaimer {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Presented {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <ExperiencePromo {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Speakers {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Slideshow {...data} />
      </ErrorBoundary>
      {data.status !== "DURING" &&
      <ErrorBoundary>
        <Agenda {...data} />
      </ErrorBoundary>
      }
      <ErrorBoundary>
        <Sponsors
          isSinglePrimaryUnderwriter={data.isSinglePrimaryUnderwriter}
          sponsor_levels={data.underwriter_levels}
          sponsors={data.underwriters}
          sponsors_label={data.underwriters_label}
          name="underwriter"
        />
      </ErrorBoundary>
      <LogoAd />
      <ErrorBoundary>
        <Sponsors
          sponsor_levels={data.partner_levels}
          sponsors={data.partners}
          sponsors_label={data.partners_label}
          name="partner"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Details {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <FeaturedLinks {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Accordions {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Questions {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Subscribe {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Related {...data} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Footer />
      </ErrorBoundary>
      <ErrorBoundary>
        <AdsConfig {...data} />
      </ErrorBoundary>
    </NavContext.Provider>
  );
};

export default EventBody;
