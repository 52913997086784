import React from "react";
import Card from "../card";
import SectionLabel from "../section-label";

const related = ({ related_events }) => {
  if (!related_events.length) {
    return null;
  }
  return (
    <section className="c-related alt-bg-color">
      <SectionLabel name="Related Events" className="events" />
      <ul
        className={`c-related-container total-${related_events.length}`}
        data-action="click link - related"
      >
        {related_events.map((event, id) => (
          <Card className="c-related-card" key={id} useInview={true} {...event} />
        ))}
      </ul>
    </section>
  );
};

export default related;
