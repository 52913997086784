import { useInView } from "react-intersection-observer";
import { useState } from "react";

/**
 * Utility for lazy-loading any component.
 *
 * @returns {{ ref: ref, shouldLoad: boolean }} - set ref on the element
 *  you want to track and use shouldLoad to toggle the src
 */
export default function useLazyLoad() {
  const [shouldLoad, setShouldLoad] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "1000px",
  });

  if (inView && !shouldLoad) {
    setShouldLoad(true);
  }

  return {
    shouldLoad,
    ref,
  };
}
