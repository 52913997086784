import React, { useRef, useState } from "react";
import Image from "../image";
import useVisibility from "../../util/use-visibility";
import useLazyLoad from "../../util/use-lazy-load";
import SectionLabel from "../section-label";
import { CardBasic } from "../card";

const SpeakerCard = ({
  sponsored,
  name,
  image,
  title,
  contact_twitter,
  contact_url,
  useInview,
}) => {
  const sponsorLabel = <p className="c-speakers-card-sponsor__label">Underwriter Session</p>;
  const { ref, shouldLoad } = useLazyLoad();

  return (
    <CardBasic className="c-speakers-card" useInview={useInview} sponsored={sponsored}>
      <Image
        ref={ref}
        className="c-speakers-card__photo"
        src={image}
        shouldLoad={shouldLoad}
        alt=""
      />
      <h3 className="c-speakers-card__name">{name}</h3>
      <div className="c-speakers-card__title" dangerouslySetInnerHTML={{ __html: title }} />
      {contact_twitter ? (
        <a
          className="c-speakers-card__link"
          href={contact_twitter}
          aria-label="Find this speaker on Twitter"
          role="button"
          data-action="click link - speakers"
        >
          Twitter
        </a>
      ) : contact_url ? (
        <a
          className="c-speakers-card__link"
          href={contact_url}
          aria-label="Contact this speaker"
          role="button"
          data-action="click link - speakers"
        >
          URL
        </a>
      ) : null}
      {sponsored ? sponsorLabel : ""}
    </CardBasic>
  );
};

const MoreSpeakersButton = ({ handleClick, speakers, isExpanded, moreButtonRef }) => {
  if (speakers.length <= 6) {
    return null;
  }
  return (
    <a
      ref={moreButtonRef}
      href="#"
      className={`c-speakers-more-button ${isExpanded ? "is-expanded" : ""}`}
      data-action="click expand - speakers"
      data-label="see all speakers"
      onClick={handleClick}
    >
      {isExpanded ? "Collapse " : "See All Speakers "}
    </a>
  );
};

const Speakers = ({ speakers = [], has_previous_speakers, accent_color = "#2C2825" }) => {
  if (!speakers.length) {
    return null;
  }

  let ref = useRef(null);
  useVisibility(ref, "speakers");
  const [isExpanded, setExpanded] = useState(false);

  // create a ref for the more speakers button scrolling behavior
  let moreButtonRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    // Scroll to the top to avoid a jump
    if (isExpanded === true) {
      moreButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      moreButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setExpanded(!isExpanded);
  };

  // Show only the first 6 speakers
  let firstSix = speakers.slice(0, 8);

  // Get the rest of the speakers
  let theRest = speakers.slice(8);

  let sectionLabelName = "Speakers";
  if (has_previous_speakers) {
    sectionLabelName = "Previous Speakers";
  }

  return (
    <section
      ref={ref}
      id="speakers"
      className="c-speakers"
      style={{ backgroundColor: accent_color }}
    >
      <SectionLabel name={sectionLabelName} className="events" theme="light" />
      <ul className="c-speakers-container">
        {firstSix.map((speaker, index) => (
          <SpeakerCard useInview="true" key={index} {...speaker} />
        ))}
      </ul>

      <div className="c-more-sticky-wrapper">
        <MoreSpeakersButton
          speakers={speakers}
          handleClick={handleClick}
          moreButtonRef={moreButtonRef}
          isExpanded={isExpanded}
        />

        <ul
          className={`c-speakers-container c-speakers-container--extras ${
            isExpanded ? "is-expanded" : ""
          }`}
        >
          {theRest.map((speaker, index) => (
            <SpeakerCard key={index} {...speaker} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Speakers;
