import React from "react";
import Link from "../link";

const SubscriberAccess = ({ subscriber_benefits }) => {
  // use default if blank or undefined
  if (!subscriber_benefits) {
    subscriber_benefits =
      "Atlantic subscribers enjoy access to exclusive sessions and experiences.";
  }
  return (
    <div className="c-subscriber-access">
      {subscriber_benefits && (
        <h3
          className="c-subscriber-access__text"
          dangerouslySetInnerHTML={{ __html: subscriber_benefits }}
        ></h3>
      )}
      <Link
        className="c-subscriber-access__link"
        action="sign in"
        href="https://accounts.theatlantic.com/accounts/details/?source=atlanticlivenav"
        target="_blank"
      >
        Sign in
      </Link>{" "}
      or{" "}
      <Link
        className="c-subscriber-access__link"
        action="subscribe"
        href={"https://accounts.theatlantic.com/products/?source=atlanticlivenav"}
        target="_blank"
      >
        Subscribe
      </Link>
    </div>
  );
};

export default SubscriberAccess;
