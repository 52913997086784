import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // TODO: log the error to an error reporting service
  // componentDidCatch(error, errorInfo) {
  // }

  render() {
    if (this.state.hasError) {
      // just render what the server rendered
      return <div suppressHydrationWarning={true} dangerouslySetInnerHTML={{ __html: "" }} />;
    }

    return this.props.children;
  }
}
