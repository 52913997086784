import React from "react";

import Image from "../image";
import Button from "../button";
import useLazyLoad from "../../util/use-lazy-load";
import { FormatLabels } from "./format-labels";

function pluralize(num, ending = "s") {
  if (num > 1) {
    return ending;
  }
  return "";
}

const SessionContent = ({
  description,
  action_url,
  action_text,
  speakersMatched,
  underwritersMatched,
  collapsed,
  start_datetime,
  end_datetime,
  location,
  format,
  tracks = [],
}) => {
  const { ref, shouldLoad } = useLazyLoad();
  const underwriterLabel = underwritersMatched.length === 1 ? "Underwriter" : "Underwriters";

  return (
    <div className={`c-session-content ${collapsed ? "is-collapsed" : ""}`} ref={ref}>
      {description && <h4 className="c-session__header">Summary</h4>}
      {description && (
        <div className="c-session__description" dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {action_url && <Button className="c-session__action" label={action_text} link={action_url} />}
      <Duration start_datetime={start_datetime} end_datetime={end_datetime} />

      {format && (
        <>
          <h4 className="c-session__header">Virtual or In-Person</h4>
          <p className="c-session__description"><FormatLabels format={format} /></p>
        </>
      )}

      {location && (
        <>
          <h4 className="c-session__header">Location</h4>
          <p className="c-session__description">{location}</p>
        </>
      )}

      {!!underwritersMatched.length && (
        <>
          <h4 className="c-session__header">{underwriterLabel}</h4>
          {underwritersMatched.map((underwriter, index) => (
            <SessionUnderwriterWrapper key={index} {...underwriter}>
              <SessionUnderwriter key={index} {...underwriter} shouldLoad={shouldLoad} />
            </SessionUnderwriterWrapper>
          ))}
        </>
      )}
      {!!speakersMatched.length && (
        <>
          <h4 className="c-session__header">Speakers</h4>
          <ul className="c-session-speakers__list">
            {speakersMatched.map((speaker, index) => (
              <Speaker key={index} {...speaker} shouldLoad={shouldLoad} />
            ))}
          </ul>
        </>
      )}
      {!!tracks.length && (
        <>
          <h4 className="c-session__header">Tracks</h4>{" "}
          <p className="c-session__description">{tracks.map((track) => track.name).join(", ")}</p>
        </>
      )}

      {/* check if a speaker is sponsored    */}
      {speakersMatched.find((person) => person.sponsored === true) && (
        <SessionUnderwrittenMessage />
      )}
    </div>
  );
};

const Speaker = ({ image, name, title, sponsored, shouldLoad }) => {
  return (
    <li className="c-session__speaker">
      <Image
        className="c-session__speaker-portrait"
        src={image}
        alt="speaker portrait"
        shouldLoad={shouldLoad}
      />
      <h5 className="c-session__speaker-name">
        {name}
        {sponsored && <span className="is-sponsored">*</span>}
      </h5>
      <div className="c-session__speaker-title" dangerouslySetInnerHTML={{ __html: title }} />
    </li>
  );
};

const SessionUnderwriterWrapper = ({ url, children }) => {
  return url ? (
    <a
      className="c-session__underwriter"
      href={url}
      target="_blank"
      rel="nofollow"
      data-action="click link - agenda"
    >
      {children}
    </a>
  ) : (
    <div className="c-session__underwriter">{children}</div>
  );
};

const SessionUnderwriter = ({ image, shouldLoad }) => {
  return (
    <Image className="c-session__underwriter-logo" shouldLoad={shouldLoad} src={image} alt="logo" />
  );
};

const SessionUnderwrittenMessage = () => (
  <li className="c-session__speaker is-sponsored">
    <sup>*</sup>This session is produced by our underwriter and is{" "}
    <a
      href="https://cdn.theatlantic.com/media/files/202203_-_atlantic_advertising_guidelines.pdf"
      target="_blank"
    >
      independent
    </a>{" "}
    of the Atlantic’s editorial staff.
  </li>
);

const Duration = ({ start_datetime, end_datetime }) => {
  // If there's no range, show nothing
  if (!start_datetime || !end_datetime) {
    return null;
  }

  // Time elapsed in minutes
  const delta = (new Date(end_datetime).getTime() - new Date(start_datetime).getTime()) / 1000 / 60;
  const hours = Math.floor(delta / 60);
  const minutes = Math.floor(delta % 60);

  return (
    <div className="c-session__duration">
      <h4 className="c-session__header">Duration</h4>
      <p>
        {hours > 0 ? `${hours} hour${pluralize(hours)} ` : ""}
        {minutes > 0 ? `${minutes} minute${pluralize(minutes)}` : ""}
      </p>
    </div>
  );
};

export default SessionContent;
