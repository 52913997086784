import { useEffect, useState } from "react";
import * as getEntitlements from "./getEntitlements";

const useEntitlements = () => {
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (getEntitlements.isSubscriber()) setIsSubscriber(true);
    if (getEntitlements.isLoggedIn()) setIsLoggedIn(true);
  }, []);

  return { isSubscriber, isLoggedIn };
};

export default useEntitlements;
