import React, { useState, useEffect } from "react";
import { assetPath } from "../constants";

const NavContainer = ({ buttonLabel, buttonLink, children }) => {
  const [closed, setClosed] = useState(true);

  // Set nav as "open" on desktop so that aria-hidden=false
  useEffect(() => {
    let mq = window.matchMedia("(max-width: 1024px)");

    function close() {
      setClosed(true);
    }

    mq.addEventListener("change", close);

    return () => {
      mq.removeEventListener("change", close);
    };
  }, []);

  return (
    <nav
      id="main-navigation"
      className="c-nav"
      aria-label="Main menu"
      data-action="click link - nav"
    >
      <div className="c-nav-container">
        <div
          className="c-nav__branding"
          itemScope
          itemType="http://www.schema.org/SiteNavigationElement"
          role="banner"
          aria-hidden="false"
        >
          <a href="https://www.theatlantic.com" className="c-nav__home" data-label="home">
            <img src={`${assetPath}/logo-a.svg`} alt="Atlantic A" />
          </a>
          <a
            className="c-nav__atlantic-logo"
            href="https://www.theatlantic.com"
            data-label="wordmark"
          >
            <span className="sr-only">The Atlantic</span>
            <img src={`${assetPath}/logo-atl.svg`} alt="Atlantic Logo" />
          </a>
        </div>

        <Hamburger buttonLabel={buttonLabel} closed={closed} setClosed={setClosed} />
        {buttonLabel ? <Button tablet={true} buttonLabel={buttonLabel} link={buttonLink} /> : null}
        <ul className={`c-nav__container ${closed ? "is-closed" : ""}`} role="menu">
          {children}
          {buttonLabel && (
            <li className="c-nav__item" role="menuitem">
              <Button buttonLabel={buttonLabel} link={buttonLink} />
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

const Hamburger = ({ buttonLabel, closed, setClosed }) => {
  // Click event for the hamburger menu
  const handleBurgerClick = (e) => {
    e.preventDefault();
    setClosed(!closed);
    const hamburgerbox = document.querySelector(".hamburger-box");
    if (closed) {
      // closed in this case means the menu is open
      hamburgerbox.setAttribute("aria-expanded", true);
    } else {
      hamburgerbox.setAttribute("aria-expanded", false);
    }
  };
  return (
    <div
      className={`hamburger-box ${buttonLabel ? "with-reg-button" : ""}`}
      onClick={handleBurgerClick}
      aria-label="Open the menu"
      role="button"
      aria-expanded="false"
    >
      <div className="hamburger-inner"></div>
    </div>
  );
};

const Button = ({ buttonLabel, link, tablet }) => {
  return (
    <a
      className={tablet ? "c-nav__tablet-register" : "c-nav__register"}
      href={link}
      aria-label={buttonLabel}
      data-action="click button - nav"
      data-label={buttonLabel}
    >
      {buttonLabel}
    </a>
  );
};

export default NavContainer;
