import React, { useRef } from "react";
import useEntitlements from "../../util/use-entitlements";
import useVisibility from "../../util/use-visibility";
import { assetPath } from "../../constants";

const Subscribe = () => {
  const { isSubscriber } = useEntitlements();
  let ref = useRef(null);
  const visible = useVisibility(ref);
  const hed = isSubscriber ? "Give The Atlantic" : "Subscribe to The Atlantic";
  const dek = isSubscriber
    ? "Share a year of ideas that will enlighten, challenge, and delight. Your gift comes with unlimited access to The Atlantic, including subscriber-only events."
    : "Unlock unlimited access to The Atlantic, including exclusive events for subscribers. Join us today and help ensure a bright future for independent journalism.";
  const link = isSubscriber
    ? "https://accounts.theatlantic.com/products/gift/?source=atlanticlivenav"
    : "https://accounts.theatlantic.com/products/?source=atlanticlivenav";

  return (
    <section ref={ref} className={`c-subscribe alt-bg-color ${visible ? "is-inview" : ""}`}>
      <img className="c-subscribe__logo" src={`${assetPath}/logo-lockup.svg`} alt="Atlantic Logo" />
      <h2 className="c-subscribe__headline">{hed}</h2>
      <p className="c-subscribe__dek">{dek}</p>
      <a
        className="c-subscribe__subscribe"
        href={link}
        data-action={`click link - ${isSubscriber ? "give a gift" : "subscribe"}`}
        data-label="subscribe"
      >
        {isSubscriber ? "Give A Gift" : "Subscribe"}
      </a>
    </section>
  );
};

export default Subscribe;
