import React from "react";

const AccessibilityDisclaimer = ({ status, accessibility_disclaimer }) => {
  const show = status === "DRAFT" || status === "UPCOMING";
  if (!show) {
    return null;
  }

  // custom disclaimer copy
  if (accessibility_disclaimer) {
    return (
      <section className="c-disclaimer alt-bg-color">
        <p
          className="c-disclaimer-text"
          dangerouslySetInnerHTML={{ __html: accessibility_disclaimer }}
        ></p>
      </section>
    );
  }

  // default disclaimer copy
  return (
    <section className="c-disclaimer alt-bg-color">
      <p className="c-disclaimer-text">
        Should you require special accommodations or for more information about accessibility,
        please contact us at{" "}
        <a href="mailto:events_audience@theatlantic.com">events_audience@theatlantic.com</a> at
        least 48 hours in advance of the event.
      </p>
    </section>
  );
};

export default AccessibilityDisclaimer;
