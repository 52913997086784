import cookie from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const jwtCookie = "atljwt";

/**
 * Parse the jwt cookie
 */
function parseJWT() {
  const token = cookie.get(jwtCookie);
  if (!token) {
    return {};
  }
  return jwtDecode(token);
}

/**
 * Looks at JWT token to see if the user has paymeter access
 */
export function isSubscriber() {
  return parseJWT().paymeter_access;
}

/**
 * Is the user is logged in
 */
export function isLoggedIn() {
  return !!cookie.get(jwtCookie);
}
