
import { useEffect, useState } from "react";

/**
 * Returns whether something has happened in the past; is currently happening; or has not happened yet
 *
 * Example use:
  const [hasHappened, isHappening, hasNotHappened] = useCompareTime(startDateTime, endDateTime);
 */
export default function useCompareTime(startDateTime, endDateTime){

  const [sessionStatus, setSessionStatus] = useState({ hasHappened: false, isHappening: false, hasNotHappened: false });

  const [currentDatetime, setCurrentDatetime] = useState(new Date());

  // Check the current date/time
  useEffect(() => {
    const timer = setTimeout(() => setCurrentDatetime(new Date()), 10000 );
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!startDateTime || !endDateTime) return;
    setSessionStatus({
      hasHappened: currentDatetime > endDateTime,
      isHappening: currentDatetime > startDateTime && currentDatetime < endDateTime,
      hasNotHappened: currentDatetime < startDateTime,
    });
  }, [currentDatetime]);

  return [sessionStatus.hasHappened, sessionStatus.isHappening, sessionStatus.hasNotHappened];
}