import React from "react";
import Image from "../image";
import useVisiblity from "../../util/use-visibility";
import useLazyLoad from "../../util/use-lazy-load";
import SectionLabel from "../section-label";

/**
 * @typedef {Object} SponsorLevel
 * @property {string} level
 * @property {Object[]} sponsors
 */

/**
 * To display the sponsors, combine the levels and sponsor data
 * @param {string[]} sponsor_levels
 * @param {Object[]} sponsors
 * @returns {SponsorLevel[]}
 */
function combineData(sponsor_levels = [], sponsors = []) {
  /**
   * An array of levels alongside the sponsors that belong to that level.
   * This will be returned from this function
   * @type {SponsorLevel[]}
   */
  let result = [];

  // if no sponsor levels exist, just put them all in one level together with no title
  if (!sponsor_levels.length) {
    result.push({
      level: undefined,
      sponsors: sponsors,
    });
  } else {
    // Set up the array of sponsorLevels
    result = sponsor_levels.map((level) => ({
      level,
      sponsors: [],
    }));
    // Add each sponsor to the correct level
    sponsors.forEach((sponsor) => {
      const index = sponsor_levels.indexOf(sponsor.level);
      // found the level that this sponsor belongs to
      if (index !== -1) {
        result[index].sponsors.push(sponsor);
      }
      // if the sponsor does not have a level, but levels are defined, the
      // sponsor will not appear in this section.
    });

    // if only one sponsor level exists, it doesn't need a title.
    if (sponsor_levels.length === 1) {
      result[0].level = undefined;
    }
  }

  // Remove levels without sponsors
  result = result.filter((lvl) => lvl.sponsors.length);

  return result;
}

const Sponsors = ({
  sponsor_levels = [],
  sponsors = [],
  sponsors_label,
  name = "",
  isSinglePrimaryUnderwriter = false,
}) => {
  const { ref, shouldLoad } = useLazyLoad();

  useVisiblity(ref, `${name}s`);

  if (!sponsors.length || isSinglePrimaryUnderwriter) {
    return null;
  }

  let sectionLabel = sponsors.length > 1 ? `${name}s` : `${name}`;
  if (sponsors_label) {
    // Custom override
    sectionLabel = sponsors_label;
  }

  return (
    <section ref={ref} id={`${name}s`} className="c-sponsors">
      <SectionLabel name={sectionLabel} className="events" theme="dark" />

      {combineData(sponsor_levels, sponsors).map((item, index) => (
        <SponsorLevel key={index} {...item} shouldLoad={shouldLoad} />
      ))}
    </section>
  );
};

const Sponsor = ({ brand, shouldLoad }) => {
  return (
    <a
      className="c-sponsor"
      href={brand.url}
      target="_blank"
      rel="nofollow"
      data-action="click link - sponsor"
    >
      <Image
        shouldLoad={shouldLoad}
        className="c-sponsor__logo c-figure--logo"
        src={brand.image}
        alt={`${brand.name} Logo`}
      />
    </a>
  );
};

/**
 * @param {Object} props
 * @param {string} props.level
 * @param {object[]} props.sponsors
 * @param {boolean} shouldLoad
 * @returns
 */
const SponsorLevel = ({ level, sponsors = [], shouldLoad }) => (
  <div className="c-sponsor-level-container">
    {level ? <h4 className="c-sponsor-level">{level}</h4> : null}
    <div className={`c-sponsor-logo-container ${level ? "" : "no-level"}`}>
      {sponsors.map((brand, index) => (
        <Sponsor key={index} brand={brand} shouldLoad={shouldLoad} />
      ))}
    </div>
  </div>
);

export default Sponsors;
