import React, { useEffect, useState, useRef } from "react";
import Button from "../button";
import useVisibility from "../../util/use-visibility";
import SectionLabel from "../section-label";

const Slideshow = ({
  slideshow_copy = "",
  slideshow_title = "",
  slideshow_images,
  register_link_cta,
  register_link,
}) => {

  if (!slideshow_title || !slideshow_images.length) return null;
  return (
    <section className="c-slideshow-section alt-bg-color">
      <SectionLabel name={slideshow_title} className="events" theme="dark" />
      <div className="c-slideshow-wrapper">
        <SlideshowBox slides={slideshow_images} />
        <div className="c-slideshow-copy">
          <div dangerouslySetInnerHTML={{ __html: slideshow_copy }}></div>
          <Button
            label={register_link_cta ? register_link_cta : "Learn More"}
            link={register_link}
          />
        </div>
      </div>
    </section>
  );
};

const SlideshowBox = ({ slides, accent }) => {
  const [current, setCurrent] = useState(0);
  let visibleRef = useRef(null);
  const visible = useVisibility(visibleRef);

  useEffect(() => {
    // auto crossfade images
    const speed = 8000;
    const interval = setInterval(() => {
      if (visible) {
        setCurrent(current === slides.length - 1 ? 0 : current + 1);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [visible, current]);

  return (
    <div className="c-slideshow">
      <div className="c-slideshow-container" ref={visibleRef}>
        {slides.map((slide, id) => (
          <img
            className={`c-slideshow_img ${current === id ? "is-active" : "inactive"}`}
            key={id}
            src={slide.image}
            alt=""
          />
        ))}
      </div>
      <ul className="c-slideshow-buttons">
        {slides.map((slide, index) => (
          <li
            className={`c-slideshow_button ${current === index ? "is-active" : "inactive"}`}
            key={index}
            onClick={() => setCurrent(index)}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default Slideshow;
