import { useEffect } from "react";
import isServer from "../../util/is-server";

const AdsConfig = ({ slug }) => {
  const gptConfig = {
    lazy_load: 2,
    datePublished: null,
    globals: {
      report: `${slug}`,
      src: "landing",
    },
    perfUrl: "https://data-cdn.theatlantic.com/perf.gif",
    zone: `/4624/Sponsored/live/${slug}`,
    adtest_domain: "dctestsite",
  };

  useEffect(() => {
    if (isServer) {
      return;
    }

    window.__ads = window.__ads || {};
    window.__ads.pageview = window.__ads.pageview || [];
    window.__ads.pause = window.__ads.pause || [];
    window.__ads.pageview.push(gptConfig);
  }, [1]);

  return null;
};

export default AdsConfig;
