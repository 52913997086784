import React, { useRef } from "react";
import useVisibility from "../util/use-visibility";

const SectionLabel = ({ name, theme = "dark", children = null, className = "" }) => {
  let labelRef = useRef(null);
  const visible = useVisibility(labelRef);
  return (
    <h2
      ref={labelRef}
      className={`c-section-label c-section-label--${className} ${theme} ${
        visible ? "is-inview" : ""
      }`}
    >
      {name}
      {children}
    </h2>
  );
};

export default SectionLabel;
