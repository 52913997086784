import React from "react";
import NavContainer from "../nav-container";
import NavLink from "../nav-link";
import useEntitlements from "../../util/use-entitlements";

const Nav = ({
  passes = [],
  contacts = [],
  underwriters = [],
  speakers = [],
  sessions = [],
  accordions = [],
  register_link,
  register_link_cta,
  sub_register_link,
  status,
  isSinglePrimaryUnderwriter,
  event_promo,
  event_promo_images,
  event_promo_nav_shown,
  contact_nav_shown,
}) => {
  // Check for subscriber status and give the correct register link
  let link;
  const { isSubscriber } = useEntitlements();
  if (isSubscriber && sub_register_link) {
    link = sub_register_link;
  } else {
    link = register_link;
  }

  let hopinButtonLabel = false;

  if (register_link_cta) {
    // Custom button text overrides all default status text
    hopinButtonLabel = register_link_cta;
  } else if (status === "DRAFT" || status === "UPCOMING") {
    hopinButtonLabel = "Register";
  } else if (status === "DURING") {
    hopinButtonLabel = "Watch Now";
  }

  const showUnderwriters = !!underwriters.length && !isSinglePrimaryUnderwriter;

  return (
    <NavContainer buttonLabel={hopinButtonLabel} buttonLink={link}>
      {passes.length > 0 && <NavLink section="passes" url="#passes" label="Passes" />}
      {event_promo_nav_shown && event_promo && event_promo_images && (
        <NavLink section="experience" url="#experience" label="Experience" />
      )}
      {speakers.length > 0 && <NavLink section="speakers" url="#speakers" label="Speakers" />}
      {sessions.length > 0 && <NavLink section="agenda" url="#agenda" label="Agenda" />}
      {showUnderwriters && (
        <NavLink
          section="underwriters"
          url="#underwriters"
          label={underwriters.length > 1 ? "Underwriters" : "Underwriter"}
        />
      )}
      {contact_nav_shown && contacts.length > 0 && <NavLink section="contact" url="#contact" label="Contact" />}
      {accordions.length > 0 && accordions.map(
        (accordion, i) => accordion.show_in_nav && <NavLink key={i} section={accordion.title} url={`#${accordion.title}`} label={accordion.title} />
      )}
    </NavContainer>
  );
};

export default Nav;
