import React, { useRef, useEffect } from "react";
import useLazyLoad from "../util/use-lazy-load";

const VideoEmbed = ({ src, title }) => {
  const { ref: videoRef, shouldLoad } = useLazyLoad();

  // Add youtube only parameters
  if (src.indexOf("youtube.com/") !== 1) {
    let connector = src.indexOf("?") === -1 ? "?" : "&";
    src = `${src}${connector}${"rel=0&hd=1"}`;
  }

  return (
    <iframe
      ref={videoRef}
      width="560"
      height="349"
      src={shouldLoad ? `${src}?rel=0&hd=1` : null}
      frameBorder="0"
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      title={title}
    ></iframe>
  );
};

const VideoInline = ({ className, src, poster, dataLabel }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  });

  const restartHandle = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = "0";
    videoRef.current.play();
    videoRef.current.setAttribute("data-action", "click play");
  };

  const unMuteHandle = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = "0";
    videoRef.current.muted = false;
    videoRef.current.play();
    videoRef.current.setAttribute("data-action", "click play");
  };

  const loopHandle = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = "0";
    videoRef.current.muted = true;
    videoRef.current.play();
  };

  const pauseHandle = () => {
    if (videoRef.current.paused) {
      videoRef.current.setAttribute("data-action", "click pause");
    }
  };

  return (
    <video
      className={className}
      ref={videoRef}
      preload="auto"
      controls="controls"
      poster={poster}
      muted
      autoPlay={true}
      playsInline
      onClick={unMuteHandle}
      onPause={pauseHandle}
      onVolumeChange={restartHandle}
      onEnded={loopHandle}
      data-action=""
      data-label={dataLabel}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default VideoEmbed;
export { VideoInline };
