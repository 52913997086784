import React, { useCallback } from "react";
import { formatTimezone } from "../../util/format-datetime";

export const TimezoneDropdown = ({
  timezone,
  onChangeTimezone,
  displayedTimezone,
  showTimezonePicker,
}) => {
  // @Hack: React was getting confused by dropping the whole DOM element
  // so we'll just hide in.
  const style = showTimezonePicker ? {} : { display: "none" };

  const options = [
    {
      label: formatTimezone(timezone),
      value: timezone,
    },
    {
      value: "",
      label: "Local Time",
    },
  ];

  return (
    <Dropdown
      label="Time Zone:"
      id="timezone"
      options={options}
      selected={options[displayedTimezone]}
      setSelected={onChangeTimezone}
    />
  );
};

export const TracksDropdown = ({ tracks = [], selected, setSelected }) => {
  if (!tracks.length) {
    return null;
  }
  let options = tracks.map((track) => {
    return {
      value: track.slug,
      label: track.name,
    };
  });
  options.unshift({
    value: "",
    label: "- Any -",
  });

  return (
    <Dropdown
      id="tracks"
      label="Track:"
      options={options}
      selected={selected}
      setSelected={setSelected}
    ></Dropdown>
  );
};

const Dropdown = ({ className, label, id, options = [], selected, setSelected }) => {
  const handleChange = useCallback(
    (event) => {
      if (event.target.value !== selected) {
        setSelected(event.target.value);
      }
    },
    [selected, setSelected]
  );

  return (
    <div className={`c-dropdown-container ${className}`}>
      <label className="c-dropdown-label" htmlFor={id}>
        {label}
      </label>
      <div className="c-dropdown-wrapper">
        <select id={id} className="c-dropdown" value={selected} onChange={handleChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span className="c-dropdown-caret" />
      </div>
    </div>
  );
};

export default Dropdown;
