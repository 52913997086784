import React, { useState, useCallback, useEffect } from "react";
import useCompareTime from "../../util/use-compare-time";

export const defaultFormat = {
  inPerson: true,
  virtual: true,
};

const TabBar = ({ datesArr = [], currentDateIndex = 0, setCurrentDateIndex, customTabNames }) => {
  if (!datesArr.length) {
    return null;
  }

  return (
    <>
      <Tabs
        datesArr={datesArr}
        currentDateIndex={currentDateIndex}
        setCurrentDateIndex={setCurrentDateIndex}
        customTabNames={customTabNames}
      />
    </>
  );
};

const Tabs = ({ datesArr = [], currentDateIndex = 0, setCurrentDateIndex, customTabNames }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    if (datesArr.length > 1) {
      setExpanded((expanded) => !expanded);
    }
  }, [setExpanded, datesArr]);

  return (
    <div
      className={`c-agenda-tabs-wrapper ${
        datesArr.length === 1 ? "c-agenda-tabs-wrapper--onedate" : ""
      }`}
    >
      <div className={`c-agenda-tabs ${expanded ? "c-agenda-tabs--expanded" : ""}`} role="tablist">
        {datesArr.map((date, index) => {
          const current = currentDateIndex === index;
          return (
            <Tab
              key={index}
              {...date}
              oneDate={datesArr.length === 1}
              tooManyDates={datesArr.length > 5}
              current={current}
              setCurrent={() => setCurrentDateIndex(index)}
              toggleExpanded={toggleExpanded}
              dayNumber={index}
              customTabNames={customTabNames}
            />
          );
        })}
      </div>
    </div>
  );
};

const Tab = ({
  current,
  setCurrent,
  oneDate,
  toggleExpanded,
  dayNumber,
  tooManyDates,
  weekday,
  customTabNames,
  sessionlist
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      if (!current) {
        setCurrent();
      }
      toggleExpanded();
    },
    [current]
  );

  let className = "c-agenda-tab";
  let isSelected = "false";
  if (current) {
    className += " c-agenda-tab--current";
    isSelected = "true";
  }
  if (oneDate) {
    className += " c-agenda-tab--lone-date";
  }

  const dayNames = ["Day One", "Day Two", "Day Three", "Day Four", "Day Five"];
  const tabName = customTabNames[dayNumber] || dayNames[dayNumber];

  // Compare the current time to the time of sessions in a given day
  const [hasHappened, isHappening, hasNotHappened] = useCompareTime(
    new Date(sessionlist[0].start_datetime),
    new Date(sessionlist[sessionlist.length - 1].end_datetime)
  );

  useEffect(() => {
  // Set the current tab to the day that is happening (if it's happening)
    if (isHappening) {
      setCurrent();
      toggleExpanded();
    }
  }, [isHappening]);

  return (
    <div className={className} onClick={handleClick} role="tab" aria-selected={isSelected}>
      {(customTabNames.length || !oneDate) && !tooManyDates && (
        <span className="c-agenda-tab__name">{tabName}</span>
      )}
      {isHappening && (<span className="is-happening"></span>)}
      <span className="c-agenda-tab__date">{weekday}</span>
    </div>
  );
};

export default TabBar;
