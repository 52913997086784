import React from "react";

const Button = ({
  className = "",
  label,
  link,
  style = "standard",
  expand = false,
  onClick,
  action = "event",
  role = "link",
}) => (
  <a
    href={link}
    aria-label={label}
    data-action={`click button - ${action.toLowerCase()}`}
    data-label={label.toLowerCase()}
    className={`c-btn c-btn--${style} ${expand ? "c-btn--expand" : ""} ${className}`}
    onClick={onClick}
    role={role}
  >
    {label}
  </a>
);

export default Button;
