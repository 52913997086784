import React, { useRef } from "react";
import useVisibility from "../../util/use-visibility";
import useEntitlements from "../../util/use-entitlements";
import SubscriberAccess from "./subscriber-access";
import VideoEmbed from "../video";

const TextColumn = ({ introButtonData, twitter_hashtag, description }) => {
  return (
    <div className="c-intro-text-col">
      <div className="c-intro-copy" dangerouslySetInnerHTML={{ __html: description }}></div>
      <a
        className="c-intro-hashtag"
        href={`https://twitter.com/search/?q=${encodeURIComponent(twitter_hashtag)}`}
        target="_blank"
      >
        {twitter_hashtag}
      </a>
      <IntroButton {...introButtonData} />
    </div>
  );
};

const IntroButton = ({
  isSubscriber,
  is_virtual,
  register_link_cta,
  sub_register_link,
  register_link,
  status,
}) => {
  // Check for subscriber status and give the correct register link
  const link = isSubscriber && sub_register_link ? sub_register_link : register_link;

  if (!link) {
    return null;
  }

  // Button text logic
  let introButtonLabel;

  if (register_link_cta) {
    // Custom button text overrides all default status text
    introButtonLabel = register_link_cta;
  } else if ((link.length && status === "DRAFT") || status === "UPCOMING") {
    // @TODO - add new in-person/virtual default text here
    introButtonLabel = "Register";
  } else if (link.length && !is_virtual && status === "DURING") {
    // In-person copy during event
    introButtonLabel = "Watch Now";
  } else if (link.length && is_virtual && status === "DURING") {
    // Virtual copy during event
    introButtonLabel = "Enter Virtual Venue";
  } else if (link.length && status === "ARCHIVED") {
    // Archived post-event text
    introButtonLabel = "Watch Now";
  }

  return (
    <a
      className="c-intro-button"
      href={link}
      aria-label={introButtonLabel}
      data-label={introButtonLabel.toLowerCase()}
    >
      {introButtonLabel}
    </a>
  );
};

const Intro = ({
  video,
  intro_image,
  intro_image_alt,
  intro_audio: intro_embed,
  description = "",
  twitter_hashtag,
  status,
  register_link_cta,
  register_link,
  sub_register_link,
  subscriber_benefits,
  is_virtual,
}) => {
  let ref = useRef(null);
  useVisibility(ref, "about");
  const visible = useVisibility(ref);

  const { isSubscriber } = useEntitlements();

  const introButtonData = {
    register_link_cta,
    register_link,
    sub_register_link,
    is_virtual,
    isSubscriber,
    status,
  };

  // Replace copy with the processed data when we have it
  return (
    <section
      ref={ref}
      className={`c-intro ${visible ? "is-inview" : ""}`}
      id="about"
      data-action="click link - intro"
    >
      {video && (
        <div className="c-intro-video">
          <VideoEmbed src={video} title="Event Trailer" />
        </div>
      )}
      <div className="c-intro-columns">
        {/* Do not show image if there is a video */}
        {intro_image && !video && (
          <img className="c-intro-image" src={`${intro_image}`} alt={`${intro_image_alt}`} />
        )}
        <TextColumn
          introButtonData={introButtonData}
          twitter_hashtag={twitter_hashtag}
          description={description}
        />
      </div>

      {intro_embed && (
        <div className="c-intro-embed" dangerouslySetInnerHTML={{ __html: intro_embed }}></div>
      )}
      {!isSubscriber ? <SubscriberAccess subscriber_benefits={subscriber_benefits} /> : null}
    </section>
  );
};

export default Intro;
