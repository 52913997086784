import React, { useRef } from "react";
import Image from "./image";
import Link from "./link";

import useLazyLoad from "../util/use-lazy-load";
import { formatFullyear } from "../util/format-datetime";
import useVisibility from "../util/use-visibility";
import getPlaceName from "../util/get-place-name";

const Card = ({
  title,
  lead_image_card_thumbnail,
  className = "",
  // if this card is on a light background, we need to add a border
  onLightBackground = true,
  url,
  useInview = false,
  start_datetime,
  custom_datetime,
  is_hybrid_event,
  is_virtual,
  city = {},
  hide_start_day,
  displayed_timezone,
  sponsored,
  alt,
  action = "event",
  children,
}) => {
  const defaultInfo = {
    title,
    start_datetime,
    custom_datetime,
    is_hybrid_event,
    is_virtual,
    city,
    hide_start_day,
    displayed_timezone,
  };
  const { ref, shouldLoad } = useLazyLoad();

  // build the full classname for the top element
  const classes = ["c-card"];
  if (onLightBackground) {
    classes.push("c-card--on-light-bg");
  }

  return (
    <CardBasic className={className} useInview={useInview} sponsored={sponsored}>
      <Link className={classes.join(" ")} href={url} action={action} label={title}>
        <Image
          ref={ref}
          className="c-card_img"
          src={lead_image_card_thumbnail}
          shouldLoad={shouldLoad}
          alt={alt}
        />
        {children ? children : <DefaultContent {...defaultInfo} />}
      </Link>
    </CardBasic>
  );
};

const CardBasic = ({ children, className, useInview = false, sponsored }) => {
  let visibleRef = useRef(null);
  const visible = useVisibility(visibleRef);
  return (
    <li
      ref={visibleRef}
      className={`c-card ${className} ${useInview && visible ? "is-inview" : ""} ${
        sponsored ? "is-sponsored" : ""
      }`}
    >
      {children}
    </li>
  );
};

const DefaultContent = ({
  title,
  start_datetime,
  custom_datetime,
  is_hybrid_event,
  is_virtual,
  city = {},
  hide_start_day,
  displayed_timezone,
}) => {
  return (
    <div className="c-card_details">
      <div className="c-card_details-border">
        <h3 className="c-card_title" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="c-card_when-and-where">
          <time className="c-card_date" dateTime={start_datetime}>
            {custom_datetime || formatFullyear(start_datetime, displayed_timezone, hide_start_day)}
          </time>
          {getPlaceName(is_hybrid_event, is_virtual, city)}
        </p>
      </div>
    </div>
  );
};

export default Card;
export { CardBasic };
