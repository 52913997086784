import React from "react";

/**
 * @param {object} params
 * @param {"BOTH" | "IN_PERSON" | "VIRTUAL"} params.format
 */
export const FormatLabels = ({ format = "BOTH" }) => (
  <span className="c-format-label">
    {format === "IN_PERSON" && <>In-Person</>}
    {format === "VIRTUAL" && <>Virtual</>}
    {format === "BOTH" && <>Virtual / In-Person</>}
  </span>
);

/**
 * @param {object} params
 * @param {"BOTH" | "IN_PERSON" | "VIRTUAL"} params.format
 */
export const FormatIcons = ({ format = "BOTH" }) => (
  <ul className="c-format-icons" aria-hidden="true">
    {(format === "IN_PERSON" || format === "BOTH") && (
      <li>
        <svg
          className="c-format-icons__icon"
          width="42"
          height="38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.552 26.647A8.63 8.63 0 0 1 8.75 24h15.5c2.331 0 4.56.956 6.198 2.647A9.132 9.132 0 0 1 33 33v5h-2v-5c0-1.867-.719-3.65-1.988-4.961A6.63 6.63 0 0 0 24.25 26H8.75a6.63 6.63 0 0 0-4.762 2.039A7.133 7.133 0 0 0 2 33v5H0v-5c0-2.377.914-4.663 2.552-6.353ZM17 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0ZM34.263 24.793l.972.235c1.918.464 3.63 1.515 4.858 3A8.198 8.198 0 0 1 42 33.25V38h-2v-4.747a6.2 6.2 0 0 0-1.448-3.95c-.943-1.14-2.273-1.965-3.787-2.331l-.972-.235.47-1.944ZM28.27.788l.971.241a9.001 9.001 0 0 1 4.864 3.1A8.595 8.595 0 0 1 36 9.5a8.595 8.595 0 0 1-1.895 5.372 9.001 9.001 0 0 1-4.864 3.099l-.97.24-.482-1.94.97-.242a7.002 7.002 0 0 0 3.784-2.407A6.595 6.595 0 0 0 34 9.5c0-1.49-.51-2.94-1.457-4.122A7.002 7.002 0 0 0 28.76 2.97l-.97-.24.482-1.942Z"
          />
        </svg>
      </li>
    )}
    {(format === "VIRTUAL" || format === "BOTH") && (
      <li>
        <svg
          className="c-format-icons__icon"
          width="45"
          height="34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 0h33v21H6V0Zm2 2v17h29V2H8ZM8.387 23.723 2.869 32h39.263l-5.519-8.277 1.664-1.11 6.555 9.832L44 34H1l-.832-1.555 6.555-9.832 1.664 1.11Z"
            fill="#C0CCDA"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M19 28h7v2h-7v-2Z" />
        </svg>
      </li>
    )}
  </ul>
);
